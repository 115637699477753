<template>
  <div class="login">
    <h1 class="my-4">
      {{ $t('LOGIN.TEXT.WELCOME_BACK') }}
    </h1>
    <div class="d-flex" v-if="showSignupLink">
      <p>
        {{ $t('LOGIN.TEXT.DON_T_HAVE_AN_ACCOUNT') }}
      </p>
      <div @click="$attrs._close()">
        <NuxtLink
          class="c-coral ms-2"
          :to="{ name: signupName, query: $route.query }"
        >
          {{ $t('GENERAL.LINK.SIGN_UP') }}
        </NuxtLink>
      </div>
    </div>
    <p v-if="isPWA && isIOS">
      {{ $t('LOGIN.TEXT.SOCIAL_LOGIN_IS_UNFORTUNATELY') }}
    </p>
    <div v-else>
      <HMButton
        class="social-button w-100"
        color="social"
        @click="facebookLogin"
      >
        <img class="me-2 social-icon" src="/assets/images/facebook-icon.svg" />
        {{ $t('LOGIN.BUTTON.SIGN_IN_WITH_FACEBOOK') }}
      </HMButton>
      <HMButton
        class="social-button w-100 mt-2"
        color="social"
        @click="googleLogin"
      >
        <img
          class="me-2 social-icon google"
          src="/assets/images/google_icon.png"
        />
        {{ $t('LOGIN.BUTTON.SIGN_IN_WITH_GOOGLE') }}
      </HMButton>
    </div>

    <div
      class="d-flex flex-row flex-nowrap justify-content-between align-items-center my-3"
    >
      <span class="hr"></span>
      <span class="text px-2 small">or</span>
      <span class="hr"></span>
    </div>
      <Form @submit="login" ref="form" v-slot="{ meta }">
        <HMTextInput
          rules="required|email"
          v-model="email"
          :placeholder="$t('GENERAL.LABEL.EMAIL')"
          :label="$t('GENERAL.LABEL.EMAIL')"
          name="email"
        />
        <HMTextInput
          rules="required"
          type="password"
          v-model="password"
          :placeholder="$t('GENERAL.LABEL.PASSWORD')"
          :label="$t('GENERAL.LABEL.PASSWORD')"
          name="password"
        />
        <HMModalFooter
          :disabled="!meta.valid || inProgress"
          :loading="inProgress"
          :submitButton="$t('LOGIN.BUTTON.LOGIN')"
          :formError="formError"
        >
          <template #close>
            <NavItem
              icon="far fa-lock-alt"
              :text="$t('GENERAL.LINK.FORGOT_PASSWORD')"
              @click.native="onForgotPassword"
              :showTextOnMobile="true"
            />
          </template>
        </HMModalFooter>
      </Form>
  </div>
</template>

<script lang="ts">
import { Form, Field, ErrorMessage } from 'vee-validate';
import uaParser from 'ua-parser-js';
import { errorToMessage } from '@/utils/errors.js';
import NavItem from '@/components/navigation/NavItem.vue';
import { mapState } from 'pinia';
import { useGlobalsStore, useUserStore, useStudioStore } from '@/stores';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    NavItem,
  },
  props: {
    onLoginFn: null,
    onResetPassword: null,
    signupName: {
      type: String,
      default: 'Signup',
    },
    showSignupLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      formError: null,
      inProgress: false,
    };
  },
  setup() {
    const userStore = useUserStore()
    const studioStore = useStudioStore()
    return {
      userStore,
      studioStore
    }
  },
  computed: {
    isIOS() {
      if(!process.client) return;
      const parsedUa = uaParser(window.navigator);
      return parsedUa.os.name === 'iOS';
    },
    ...mapState(useGlobalsStore, {
      isPWA: state => state.isPWA,
    }),
  },
  methods: {
    login() {
      this.inProgress = true;
      this.userStore
        .login({ email: this.email, password: this.password })
        .then(this.onLogin)
        .catch(error => {
          this.formError = errorToMessage(error.response);
        })
        .finally(() => (this.inProgress = false));
    },
    async onLogin(loginResponse: LoginResponse) {
      const studioURL = this.$studioURL
      if (studioURL) {
        await this.studioStore.joinStudio(studioURL)
        await this.studioStore.getStudio(studioURL)
      }
      if (this.onLoginFn) {
        if(this.$attrs._close) this.$attrs._close();
        await this.loadUserInfoSync();
        this.onLoginFn();
      } else {
        const { user_type } = loginResponse;
        const redirectUrl = this.$route.query.redirect;
        await this.loadUserInfoSync();
        this.$emit('onLogin');
        if (redirectUrl) {
          this.$router.replace(redirectUrl);
        } else {
          const redirectData = this.userStore
            .getLoginRedirectData(studioURL);

          this.$router.replace(redirectData);
        }
      }
    },
    async onForgotPassword() {
      if (this.onResetPassword) {
        this.onResetPassword();
      }
      this.$emit('onResetPassword');
      this.$attrs._close()
    },
    async loadUserInfoSync() {
      let loadUserInfoPromise = this.userStore.userInfoPromise;
      if (!loadUserInfoPromise) {
        loadUserInfoPromise = await this.userStore.loadUserInfo();
      } else {
        loadUserInfoPromise
          .finally()
          .then(
            async () =>
              (loadUserInfoPromise = await this.userStore.loadUserInfo())
          );
      }
      await loadUserInfoPromise;
    },
    socialLoginError(error) {
      let errorMessage;
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          errorMessage = this.$t('LOGIN.TEXT.WE_VE_GOT_AN_ACCOUNT_WITH_THIS');
          break;
        default:
          errorMessage = errorToMessage(error.response);
          break;
      }

      this.$notify({
        group: 'app',
        type: 'error',
        text: errorMessage,
      });
    },
    async facebookLogin() {
      try {
        const provider = new this.$firebase.auth.FacebookAuthProvider();
        const result = await this.$firebase.auth().signInWithPopup(provider);
        const loginResponse = await useUserStore().loginWithFacebook(result.credential.accessToken);
        this.onLogin(loginResponse);
      } catch (error) {
        this.socialLoginError(error);
      }
    },
    async googleLogin() {
      try {
        const provider = new this.$firebase.auth.GoogleAuthProvider();
        const result = await this.$firebase.auth().signInWithPopup(provider);
        const loginResponse = await useUserStore().loginWithGoogle(result.credential.accessToken);
        this.onLogin(loginResponse);
      } catch (error) {
        this.socialLoginError(error);
      }
    },
    async appleLogin() {
      try {
        const provider = new this.$firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        const result = await this.$firebase.auth().signInWithPopup(provider);
        const loginResponse = await useUserStore().loginWithApple(result.credential.accessToken);
        this.onLogin(loginResponse);
      } catch (error) {
        this.socialLoginError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.social-icon {
  width: 30px;
  height: 20px;
  &.google {
    height: 30px;
  }
}

.social-button {
  font-family: 'Roboto', sans-serif;
}

.hr {
  border-bottom: 1px solid $light-gray;
  width: 100%;
}
</style>
