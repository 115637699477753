<template>
  <Login
    :onLoginFn="onLoginFn"
    signupName="studio-signup"
    :showSignupLink="showSignupLinkForStudio"
    @onResetPassword="() => $router.push({ name: 'studio-get-reset-password' })"
  />
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import Login from '@/components/signup/Login.vue';

export default {
  name: 'StudentLogin',
  components: {
    Login,
  },
  props: {
    onLoginFn: {
      type: Function,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      showSignupLinkForStudio: 'showSignupLinkForStudio',
    }),
  },
};
</script>
